<template>
  <div class="card-event w-full mb-8">
    <figure class="block rounded-lg overflow-hidden">
      <!-- <nuxt-img format="webp" :src="event.cover_picture_url" class="w-full h-auto min-h-[140px]" /> -->
      <Blurhash :isLoading="isLoading" :alt="event.name" :src="event.cover_picture_webp_url" :hash="event.cover_picture_blurhash" :punch="1" ></Blurhash>
    </figure>

    <div class="p-2">
      <div>
        <div v-if="isLoading" class="h-8 bg-gray-100 rounded-full dark:bg-zinc-800 w-full mb-2 mt-1 animate-pulse"></div>
        <h2 v-else class="text-2xl mb-2 font-bold">{{ event.name }}</h2>
      </div>

      <div v-if="isLoading" class="">
        <div class="flex animate-pulse">
          <div class="h-5 bg-gray-100 rounded-full dark:bg-zinc-800 w-1/2 mb-2.5"></div>
          <div class="h-5 bg-gray-100 rounded-full dark:bg-zinc-800 w-1/2 mb-2.5"></div>
        </div>
        <div class="flex animate-pulse">
          <div class="h-5 bg-gray-100 rounded-full dark:bg-zinc-800 w-1/2 mb-2.5"></div>
          <div class="h-5 bg-gray-100 rounded-full dark:bg-zinc-800 w-1/2 mb-2.5"></div>
        </div>
      </div>
      <div v-else class="">

        <div class="grid grid-cols-2 gap-y-2">
          <div v-if="event.producer" class="flex">
            <div class="flex items-center justify-center w-[32px]">
              <img v-if="event.producer.avatar_picture_webp_url" :src="event.producer.avatar_picture_webp_url" alt="" class="w-5 h-5 rounded-full shadow-lg" width="32" height="32">
            </div>
            <p class="col-span-5 text-ellipsis pr-1 overflow-clip text-sm truncate">{{ event.producer.name }}</p>
          </div>

          <div v-if="event.venue" class="flex">
            <div class="flex items-center justify-center w-[32px]">
              <icon-location class="w-4" />
            </div>
            <p class="col-span-5 text-ellipsis pr-1 overflow-clip text-sm truncate">{{ event.venue.name }}</p>
          </div>
          <div v-if="!event.venue && event.secret_location" class="flex">
            <div class="flex items-center justify-center w-[32px]">
              <icon-location class="w-4" />
            </div>
            <p class="col-span-5">local secreto</p>
          </div>

          <div v-if="event.event_starts_at" class="flex">
            <div class="flex items-center justify-center w-[32px]">
              <icon-calendar class="w-4" />
            </div>
            <p class="col-span-5">{{ $filters.friendlyDateShort(event.event_starts_at) }}</p>
          </div>

          <div v-if="ticketsStartingFrom(event)" class="flex">
            <div class="flex items-center justify-center w-[32px]">
              <icon-ticket class="w-4" />
            </div>
            <p class="col-span-5">{{ $filters.asCurrency(ticketsStartingFrom(event)) }}</p>
          </div>
        </div>
      </div>

      <EventTags :tags="event.tags" :tagList="event.tag_list" />

    </div>


  </div>
</template>

<script>
export default {
  name: 'EventCardFull',
  props: {
    event: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    checkAvailability (marketing_ui_state) {
      return !['sell_didnt_start', 'unavailable'].includes(marketing_ui_state)
    },
    ticketsStartingFrom (event) {
      try {
        const ourProduct = event
          .products
          .filter(product => this.checkAvailability(product.marketing_ui_state))
          .sort((a, b) => a.amount - b.amount)[0]
          .amount
        return ourProduct
      } catch(_) {
        return false
      }
    },
  }
}
</script>
