<template>
  <div class="w-full overflow-auto flex flex-wrap my-1 mt-2 gap-1">
    <div v-for="tag in tags" class="px-1 py-1 border border-gray-200 text-gray-600 bg-gray-100 dark:bg-zinc-950 dark:border-zinc-800 dark:text-gray-400 text-xs rounded-lg inline-block">
      {{ tag.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'EventTags',
  computed: {

  },
  props: {
    tags: {
      required: false,
      type: Array,
      default () {
        return []
      }
    },
    tagList: {
      required: false,
      type: String
    }
  }
}
</script>

